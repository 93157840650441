<template>
  <div class="drawer-goods" :class="{show,success:show&&goods.collected === goods.pivot.amount}">
    <div v-if="show">
      <p>
        <strong>[{{ goods.code }}]</strong> {{ goods.name }}
        <a href="#" @click.prevent="$emit('preview', goods.photo)">изображение</a>
      </p>
      <div class="flex">
        <form @submit.prevent="$emit('cancel')">
          <button type="button" @click="count(-1)">-</button>
          <input type="number" v-model="goods.collected" min="0" step="1"> / <strong>{{ goods.pivot.amount }}</strong>
          <button type="button" @click="count(1)">+</button>
        </form>
        <button @click="$emit('cancel')">✅</button>
      </div>
    </div>

    <ModalImage :uri="image_uri" @close="image_uri = ''"/>

  </div>
</template>

<script>
import {watch, toRefs, ref} from "vue";
import ModalImage from "@/components/ModalImage";

export default {
  components: {ModalImage},
  props: {
    goods: {
      type: Object,
      require: true
    }
  },
  setup(props, {emit}) {
    const {goods} = toRefs(props)
    let show = ref(false)
    let image_uri = ref('')

    const showHide = async (newVal) => {
      show.value = !!newVal.id
    }
    watch(goods, showHide)

    const count = (val) => {
      try {
        navigator.vibrate(100);
      } catch (er) {
      }
      emit(val > 0 ? 'plus' : 'minus')
    }

    const enter = (event) => {
      if (event.code === 'Enter') emit('cancel')
    }

    return {
      show, count, enter, image_uri
    }
  }
}
</script>

<style lang="less">
.drawer-goods {
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0 0 #555;
  transform: translateY(100%);

  > div {
    max-width: 960px;
    margin: 0 auto;
    padding: 10px;
  }

  &.show {
    box-shadow: 0 0 35px #555;
    transform: translateY(0);
  }

  &.success {
    background: #91e991;
  }

  p {
    margin: 0 0 20px;
    font-size: 14px;
  }

  button {
    width: 40px;
  }

  .flex {
    display: flex;
    justify-content: space-between;

    button:first-child {
      margin-right: 10px;
    }

    button:last-child {
      margin-left: 10px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    input {
      width: 80px;
      text-align: center;
      margin: 0;
    }
  }
}
</style>

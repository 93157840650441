<template>
  <div class="modal-confirm" :class="{show}">
    <div class="body">
      <template v-if="!status">
        <slot name="title"/>
        <slot name="body"/>
        <div class="btns">
          <button @click="$emit('confirm')">Да</button>
          <button @click="$emit('cancel')">Нет</button>
        </div>
      </template>
      <template v-else-if="status === 'success'">
        <h3>Успех!</h3><br>
        <button class="full" @click="$emit('cancel')">Закрыть</button>
      </template>
      <template v-else-if="status === 'error'">
        <slot name="title"/>
        <slot name="body"/>
        <button class="full" @click="$emit('cancel')">Закрыть</button>
      </template>
      <Loader :state="status" v-else/>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/Loader";
export default {
  components: {Loader},
  props: {
    show: {
      type: Boolean,
      require: true
    },
    status: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="less">
.modal-confirm {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, 0.8);
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.show {
    visibility: visible;
  }

  .body {
    background: #fff;
    padding: 10px 20px;
    width: calc(100% - 20px);
    max-width: 600px;

    h3 {
      margin: 0;
      text-align: center;
    }

    p {
      margin: 20px 0;
    }

    .btns {
      display: flex;
      justify-content: space-between;

      button {
        width: calc(50% - 5px);
      }
    }
  }
}
</style>

<template>
  <div class="modal-image">
    <div v-show="uri">
      <div>
        <code>ESC</code>
        <button @click="$emit('close')">Закрыть</button>
        <img alt="cover" :src="uri">
        <div class="loader">
          <Loader :state="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, onUnmounted, watch, ref} from "vue";
import Loader from "@/components/Loader";

export default {
  components: {Loader},
  props: {
    uri: {
      type: String,
      require: true,
      default: ''
    }
  },
  setup(props, {emit}) {

    const closeByEsc = (event) => {
      if (event.code === 'Escape') emit('close')
    }
    onMounted(() => {
      document.addEventListener('keydown', closeByEsc)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', closeByEsc)
    })

    let loading = ref(false)

    watch(() => props.uri, (newVal, oldVal) => {
      if (newVal) {
        if (window.getSelection) window.getSelection().removeAllRanges()
        document.querySelector('body').classList.add('overflow')

        loading.value = true
        let img = new Image()
        img.src = newVal
        img.onload = () => {
          loading.value = false
        }

      }
      if (oldVal) {
        document.querySelector('body').classList.remove('overflow')
      }
    })

    return {
      loading
    }
  }
}
</script>

<style lang="less" scoped>
.modal-image {
  > div {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      position: relative;
      width: 100%;
      max-width: 600px;
      height: calc(100% - 20px);
      box-shadow: 0 0 35px #555;
      background: #fff;
      padding-bottom: 35px;
      margin: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      code {
        position: absolute;
        z-index: 2;
        bottom: 35px;
        left: 15px;
        background: #a0a0a0;
        color: #fff;
        font-size: 10px;
        padding: 0 5px;
      }

      .loader {
        position: absolute;
        top: calc(50% - 30px);
        left: 0;
        right: 0;
      }

      button {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}
</style>

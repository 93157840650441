<template>
  <div class="page-order">
    <InputSearch code="F1" placeholder="Штрихкод" @submit="setGoods" ref="inputCode"/>

    <template v-if="!load && goods.length">
      <table v-show="goods.filter(g => g.collected!==g.pivot.amount).length">
        <tr>
          <th>
            #
          </th>
          <th>
            Наименование
          </th>
          <th>
            Кол.
          </th>
        </tr>
        <tr v-for="(g,i) in goods.filter(g_ => g_.collected!==g_.pivot.amount)" :key="g.id"
            :class="{error:g.collected>0&&g.collected!==g.pivot.amount}">
          <td class="num">
            {{ i + 1 }}
          </td>
          <td>
            <small v-if="!g.barcodes.length">Штрихкод:
              <strong class="text-red">не назначен</strong><br>
            </small>
            <b>[{{ g.code }}]</b> {{ g.name }}<br>
            <a href="#" @click.prevent="image_uri = g.photo">изображение</a>
            <template v-if="!g.barcodes.length">
              <br><br><button @click="select(g)">Назначить штрихкод</button>
            </template>
          </td>
          <td>
            <strong v-if="g.collected!==g.pivot.amount">{{ g.collected }}</strong>
            <strong class="amount" :class="{success:g.collected===g.pivot.amount}">{{ g.pivot.amount }}</strong>
            <br><button @click="selectGoods = g">✅</button>
          </td>
        </tr>
      </table>

      <template v-if="goodsFocus">
        <h3>2. Сканируйте штрихкод для товара</h3>
        <h4>{{ goodsFocus.name }}</h4>

        <InputSearch code="F2" placeholder="Штрихкод" @submit="checkCode" ref="inputSetCode"/>
        <Loader :state="checkCodeLoad"/>

        <template v-if="goodsFromCode">
          <table>
            <tr>
              <th>
                Под этим кодом уже есть товар:
              </th>
            </tr>
            <tr>
              <td>
                <b>[{{goodsFromCode.code}}]</b> {{ goodsFromCode.name }}
                <a href="#" @click.prevent="image_uri = goodsFromCode.photo">изображение</a>
              </td>
            </tr>
          </table>
          <button class="full" @click="showConfirm = true">Перезаписать штрих-код</button>
        </template>

      </template>

      <table v-show="goods.filter(g => g.collected===g.pivot.amount).length">
        <tr>
          <th>
            #
          </th>
          <th>
            Наименование
          </th>
          <th>
            Кол.
          </th>
        </tr>
        <tr v-for="(g,i) in goods.filter(g_ => g_.collected===g_.pivot.amount)" :key="g.id" class="success">
          <td class="num">
            {{ i + 1 }}
          </td>
          <td>
            <small v-if="!g.barcodes.length">Штрихкод:
              <strong class="text-red">не назначен</strong><br>
            </small>
            <b>[{{ g.code }}]</b> {{ g.name }}<br>
            <a href="#" @click.prevent="image_uri = g.photo">изображение</a>
          </td>
          <td>
            <strong v-if="g.collected!==g.pivot.amount">{{ g.collected }}</strong>
            <strong class="amount" :class="{success:g.collected===g.pivot.amount}">{{ g.pivot.amount }}</strong>
            <small v-if="g.collected!==g.pivot.amount">ост.<br>{{ g.amount }}</small>
            <br><button @click="selectGoods = g">✅</button>
          </td>
        </tr>
      </table>
    </template>
    <EmptyData v-else-if="!load"/>

    <ModalImage :uri="image_uri" @close="image_uri = ''"/>

    <ModalConfirm :show="showModalError" status="error" @cancel="closeClear">
      <template #title><h3 class="text-red">Такого товара <br>в поставке не должно быть!</h3></template>
      <template #body><p></p></template>
    </ModalConfirm>

    <ModalConfirm :show="showConfirm" :status="status" @confirm="confirm" @cancel="cancel">
      <template #title><h3>Названить код: {{ code }} ?</h3></template>
      <template #body>
        <p v-if="goodsFromCode">{{ goodsFromCode.name }}</p>
        <div class="flex">
          <div>
            <button type="button" @click="change(-1)">-</button>
            <input type="number" v-model="amount" min="1" step="1">
            <button type="button" @click="change(1)">+</button>
          </div>
          <small>Введите кол-во, если это коробка с несколькими товарами</small>
        </div>
      </template>
    </ModalConfirm>

    <DrawerGoods :goods="selectGoods"
                 @preview="src => image_uri = src"
                 @plus="selectGoods.collected++"
                 @minus="selectGoods.collected > 0 ? selectGoods.collected-- : 0"
                 @cancel="closeClear"/>

  </div>
</template>

<script>
import InputSearch from "@/components/InputSearch";
import {onMounted, onUnmounted, ref} from "vue";
import {useRoute} from "vue-router"
import EmptyData from "@/components/EmptyData";
import ModalConfirm from "@/components/ModalConfirm";
import DrawerGoods from "@/components/DrawerGoods";
import ModalImage from "@/components/ModalImage";
import Loader from "@/components/Loader";

export default {
  components: {Loader, ModalImage, DrawerGoods, ModalConfirm, EmptyData, InputSearch},
  setup(props, {emit}) {
    const route = useRoute()
    emit('meta', {title: 'Поставка №' + route.params.id, back: '/supplies'})

    let load = ref('Загрузка...')
    let goods = ref([])
    let image_uri = ref('')

    const getData = async () => {
      load.value = 'Загрузка...'
      let {data} = await axios.get('v1/supplies/' + route.params.id)
      data.goods.map(g => g.collected = 0)
      goods.value = data.goods
      load.value = ''
    }
    getData()

    let selectGoods = ref({});
    let showModalError = ref(false)
    const setGoods = async ({search: barcode}) => {
      if (!barcode) {
        selectGoods.value = {}
        return
      }
      let _goods = goods.value.filter(g => g.barcodes.find(b => b.barcode === barcode))[0]
      if(!_goods){
        showModalError.value = true
      } else {
        selectGoods.value = _goods
        selectGoods.value.collected += _goods.barcodes.find(b => b.barcode === barcode).amount
      }
    }

    let goodsFocus = ref(null)
    const inputSetCode = ref(null)
    const select = (g) => {
      goodsFocus.value = g
      setTimeout(() => {
        inputSetCode.value.input.focus()
      })
    }

    let code = ref('')
    let checkCodeLoad = ref('')
    let goodsFromCode = ref(null)
    const checkCode = async (params) => {
      code.value = params.search
      checkCodeLoad.value = 'проверка кода...'
      goodsFromCode.value = null
      if (params.search) {
        const data = await api.getGoodsFind(params.search)
        goodsFromCode.value = data
        if (data === '') showConfirm.value = true
      }
      checkCodeLoad.value = ''
    }

    let showConfirm = ref(false)
    let status = ref('')
    const confirm = async () => {
      let params = {
        id: goodsFocus.value.id,
        code: code.value,
        amount: amount.value
      }

      status.value = 'В процессе...'
      status.value = await api.setGoods(params)
    }

    const cancel = () => {
      showConfirm.value = false
      goodsFocus.value = null
      goodsFromCode.value = null
      if (status.value === 'success') {
        status.value = ''
        amount.value = 1
        getData()
      }
    }

    let amount = ref(1)
    const change = (val) => {
      try {
        navigator.vibrate(100);
      } catch (er) {}
      amount.value += val
      if(amount.value < 1) amount.value = 1
    }

    const inputCode = ref(null)
    const closeClear = () => {
      selectGoods.value = {}
      showModalError.value = false
      inputCode.value.input.value = ''
    }

    const keydown = (event) => {
      if (event.code === 'F13' && inputCode.value.input !== document.activeElement
          && inputSetCode.value.input !== document.activeElement) {
        inputCode.value.input.value = ''
        inputCode.value.input.focus()
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', keydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', keydown)
    })

    return {
      load, goods, getData, image_uri, inputCode,
      showModalError, closeClear,
      setGoods, selectGoods,
      select, inputSetCode, goodsFocus, checkCodeLoad, checkCode, goodsFromCode, showConfirm, confirm, cancel, status,
      amount, change, code
    }
  }
}
</script>

<style lang="less">
.page-order {
  tr.success td {
    background: #91e991;
  }
  tr.error td {
    background: #eea7a7;
  }

  .amount {
    display: block;
    margin: 10px 0 0;
    color: red;
    &.success {
      color: green;
    }
  }

  h3 {
    font-size: 18px;
    text-align: center;
    margin: 10px 0;
  }
  h4 {
    font-size: 16px;
    text-align: center;
    margin: 10px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    input {
      width: 80px;
      text-align: center;
      margin: 0 10px;
    }

    small {
      margin: 10px 0;
    }
  }
}
</style>
